import Image from "next/image";
import "@brainhubeu/react-carousel/lib/style.css";
import { Navigation, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/autoplay";

import myAccountDefaultImage from "../public/images/blank-profile-picture_1280.png";

function TestimonialSlider(props) {
  const testimonials = props.testimonials;

  // Helper function to limit words
  const limitWords = (text, wordLimit) => {
    const words = text.split(" ");
    if (words.length > wordLimit) {
      return words.slice(0, wordLimit).join(" ") + "...";
    }
    return text;
  };

  const breakpoints = {
    320: {
      slidesPerView: 1,
    },
    480: {
      slidesPerView: 2,
    },
    640: {
      slidesPerView: 3,
    },
  };

  return (
    <>
      {testimonials ? (
        <Swiper
          modules={[Navigation, Pagination, Autoplay]}
          spaceBetween={20}
          slidesPerView={3}
          navigation
          autoplay={{ delay: 3000 }}
          pagination={{ clickable: true }}
          breakpoints={breakpoints}
        >
          {testimonials.map((testimonial) => (
            <SwiperSlide key={testimonial.id}>
              <div className="rounded shadow-sm p-3 h-100">
                <div>
                  <svg
                    width="28"
                    height="20"
                    viewBox="0 0 31 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M3.43164 21.9789C1.73791 20.1158 0.72168 18.0833 0.72168 14.6959C0.72168 8.76787 4.95599 3.51733 10.884 0.807373L12.4084 3.00921C6.81908 6.05791 5.63348 9.95348 5.29473 12.4941C6.14159 11.9859 7.3272 11.8166 8.5128 11.9859C11.5615 12.3247 13.9327 14.6959 13.9327 17.914C13.9327 19.4383 13.2552 20.9627 12.239 22.1483C11.0534 23.3339 9.69841 23.842 8.00469 23.842C6.14159 23.842 4.44787 22.9951 3.43164 21.9789ZM20.3689 21.9789C18.6751 20.1158 17.6589 18.0833 17.6589 14.6959C17.6589 8.76787 21.8932 3.51733 27.8212 0.807373L29.3456 3.00921C23.7563 6.05791 22.5707 9.95348 22.232 12.4941C23.0788 11.9859 24.2644 11.8166 25.45 11.9859C28.4987 12.3247 30.8699 14.6959 30.8699 17.914C30.8699 19.4383 30.1925 20.9627 29.1762 22.1483C28.16 23.3339 26.6356 23.842 24.9419 23.842C23.0788 23.842 21.3851 22.9951 20.3689 21.9789Z"
                      fill="#ececec"
                    />
                  </svg>
                </div>
                <div className="text-dark testitxt">
                  {limitWords(testimonial.Content, 30)}
                </div>
                <div className="d-flex justify-content-end">
                  <svg
                    width="28"
                    height="20"
                    viewBox="0 0 31 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M27.7695 2.44638C29.4633 4.30948 30.4795 6.34195 30.4795 9.72939C30.4795 15.6574 26.2452 20.908 20.3172 23.6179L18.7928 21.4161C24.3821 18.3674 25.5677 14.4718 25.9064 11.9312C25.0596 12.4393 23.874 12.6087 22.6884 12.4393C19.6397 12.1006 17.2685 9.72939 17.2685 6.51132C17.2685 4.98697 17.9459 3.46262 18.9622 2.27701C20.1478 1.0914 21.5028 0.583287 23.1965 0.583288C25.0596 0.583288 26.7533 1.43015 27.7695 2.44638ZM10.8323 2.44638C12.526 4.30948 13.5423 6.34194 13.5423 9.72939C13.5423 15.6574 9.30796 20.908 3.37992 23.6179L1.85558 21.4161C7.44486 18.3674 8.63047 14.4718 8.96921 11.9312C8.12235 12.4393 6.93674 12.6087 5.75114 12.4393C2.70244 12.1006 0.331226 9.72939 0.331226 6.51132C0.331226 4.98696 1.00872 3.46262 2.02495 2.27701C3.04118 1.0914 4.56553 0.583286 6.25926 0.583286C8.12235 0.583286 9.81607 1.43015 10.8323 2.44638Z"
                      fill="#ececec"
                    />
                  </svg>
                </div>
                <div className="d-flex align-items-center gap-2">
                  <div className="testiimg">
                    <Image
                      src="/images/testi-avtr.webp"
                      className="rounded-circle"
                      width={100}
                      height={100}
                      alt={testimonial.name}
                    />
                  </div>
                  <div>
                    <div className="testitit">
                      {testimonial.name}, {testimonial.city}
                    </div>
                    <div className="d-flex">
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.65391 12.6198L2.93091 15.3049L3.9879 9.90733L0.0141602 6.17263L5.39276 5.51838L7.65391 0.516059L9.91507 5.51838L15.2937 6.17263L11.3199 9.90733L12.3769 15.3049L7.65391 12.6198Z"
                          fill="#FFB200"
                        />
                      </svg>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.65391 12.6198L2.93091 15.3049L3.9879 9.90733L0.0141602 6.17263L5.39276 5.51838L7.65391 0.516059L9.91507 5.51838L15.2937 6.17263L11.3199 9.90733L12.3769 15.3049L7.65391 12.6198Z"
                          fill="#FFB200"
                        />
                      </svg>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.65391 12.6198L2.93091 15.3049L3.9879 9.90733L0.0141602 6.17263L5.39276 5.51838L7.65391 0.516059L9.91507 5.51838L15.2937 6.17263L11.3199 9.90733L12.3769 15.3049L7.65391 12.6198Z"
                          fill="#FFB200"
                        />
                      </svg>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.65391 12.6198L2.93091 15.3049L3.9879 9.90733L0.0141602 6.17263L5.39276 5.51838L7.65391 0.516059L9.91507 5.51838L15.2937 6.17263L11.3199 9.90733L12.3769 15.3049L7.65391 12.6198Z"
                          fill="#FFB200"
                        />
                      </svg>
                      <svg
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M7.65391 12.6198L2.93091 15.3049L3.9879 9.90733L0.0141602 6.17263L5.39276 5.51838L7.65391 0.516059L9.91507 5.51838L15.2937 6.17263L11.3199 9.90733L12.3769 15.3049L7.65391 12.6198Z"
                          fill="#FFB200"
                        />
                      </svg>
                    </div>
                  </div>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      ) : (
        <p>No Testimonial Found!</p>
      )}
    </>
  );
}

export default TestimonialSlider;
